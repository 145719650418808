<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Supplier</h5>
            </template>

            <!-- FILTER -->
            <div class="row">
                <div class="col-lg-3">
                    <b-form-group label="Nama Supplier" label-for="filterInput">
                        <b-form-input v-model="filter.search" id="filterInput" type="search"
                            placeholder="cari nama supplier"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-lg-3">
                    <b-form-group label="Type Supplier" label-for="filterType">
                        <multiselect v-model="filter.type_id" name="type_id" id="filterType" :internal-search="true"
                            :options="supplierTypeAll" :allow-empty="true" deselect-label="" select-label="" label="type"
                            track-by="id" placeholder="pilih tipe" />
                    </b-form-group>
                </div>
                <div class="col-lg-3">
                    <b-form-group label="Bank" label-for="bank">
                        <multiselect v-model="filter.bank_id" name="bank_id" id="bank" :internal-search="true"
                            :options="bankOptions" :allow-empty="true" deselect-label="" select-label="" label="name"
                            track-by="id" placeholder="pilih bank" />
                    </b-form-group>
                </div>
                <div class="col-2 d-flex align-items-end mb-3">
                    <b-button @click="filterSupplier" class="mr-2" variant="pgiBtn">Filter</b-button>
                    <b-button @click="filterReset" variant="pgiBtn">Reset</b-button>
                </div>
            </div>
            <!-- FILTER -->




            <b-row>
                <!-- <b-col md="6">
                    <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                        <div class="d-flex search-input-wrapper">
                            <b-form-input class="ml-2 mr-2" v-model="filter" name="filterTable" id="filterInput"
                                @keyup="getSuplier()" type="search" placeholder="Type to search"></b-form-input>
                            <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                                @click="onFilter">
                                <i class="fa fa-search"></i>
                            </b-button>
                            <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                                @click="onReset()">Reset</b-button>
                        </div>
                    </b-form-group>
                </b-col> -->

                <b-col>
                    <div class="d-flex justify-content-end mb-2">
                        <b-button class="ml-2" variant="pgiBtn" @click="$bvModal.show('m-create')">
                            <i class="fa fa-plus"></i> Tambah Suplier
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <b-table bordered hover show-empty :busy="isLoading" :items="suplier" :fields="fields" :perPage="perPage"
                responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>

                <template v-slot:cell(bank)="row">
                    {{ row.item.nama_bank }}
                </template>
                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Link"
                            name="suplierEditBtn">
                            <i class="fa fa-external-link"></i>
                        </b-button>
                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Edit"
                            name="kategoriEditBtn" @click="showModalEdit(row.item.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </b-button>

                        <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Delete"
                            name="suplierEditBtn" @click="showModalHapus(`${row.item.id}`)">
                            <i class="fa fa-trash text-danger"></i>
                        </b-button>

                    </div>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>
        <b-modal id="m-create" size="lg" title="Formulir Suplier" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Nama Supplier" for="nama-suplier">
                        <b-form-input v-model="form.nama" id="nama-suplier" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="No HP/Telp" for="hp">
                        <b-form-input v-model="form.no_hp" id="hp" type="number"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Tipe Supplier" for="supplier_type_id">
                        <b-form-select id="supplier_type_id" v-model="form.supplier_type_id">
                            <option :value="null" disabled>pilih type</option>
                            <option :selected="form.supplier_type_id == supplierType.id"
                                v-for="supplierType in supplierTypeAll" :key="supplierType.id" :value="supplierType.id">
                                {{ supplierType.type }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Bank">
                        <multiselect v-model="bank" :options="bankOptions" @select="handleSelectBank"
                            :internal-search="true" :allow-empty="false" deselect-label="" select-label="" label="name"
                            track-by="id" placeholder="pilih bank" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="No Rekening" for="no_rekening">
                        <b-form-input v-model="form.no_rekening" id="no_rekening" type="number"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                    <b-form-group label="Pemilik Rekening" for="nama_rekening">
                        <b-form-input placeholder="nama pemilik rekening" v-model="form.nama_rekening" id="nama_rekening"
                            type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Alamat" for="alamat">
                        <b-form-textarea v-model="form.alamat" id="alamat" placeholder="Alamat"></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Situs Web / Email" for="web-email">
                        <b-form-input v-model="form.link_web" id="web-email" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="keterangan">
                        <b-form-textarea v-model="form.keterangan" id="keterangan"
                            placeholder="Keterangan"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <!-- MODAL Edit -->
        <b-modal id="m-edit" size="lg" title="Formulir Edit Suplier" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Nama Suplier" for="nama-suplier">
                        <b-form-input v-model="formEdit.nama" id="nama-suplier" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="No HP/Telp" for="hp">
                        <b-form-input v-model="formEdit.no_hp" id="hp" type="number"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Tipe Supplier" for="supplier_type_id">
                        <b-form-select id="supplier_type_id" v-model="formEdit.supplier_type_id">
                            <option :value="null" disabled>pilih type</option>
                            <option :selected="formEdit.supplier_type_id == supplierType.id"
                                v-for="supplierType in supplierTypeAll" :key="supplierType.id" :value="supplierType.id">
                                {{ supplierType.type }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="Bank">
                        <b-form-select id="bank_id" v-model="formEdit.bank_id">
                            <option :value="null" disabled>pilih bank</option>
                            <option v-for="item in bankOptions" :key="item.id" :value="item.id">
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="No Rekening" for="no_rekening">
                        <b-form-input v-model="formEdit.no_rekening" id="no_rekening" type="number"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                    <b-form-group label="Nama Rekening" for="nama_rekening">
                        <b-form-input v-model="formEdit.nama_rekening" id="nama_rekening" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Alamat" for="alamat">
                        <b-form-textarea v-model="formEdit.alamat" id="alamat" placeholder="Alamat"></b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Situs Web / Email" for="web-email">
                        <b-form-input v-model="formEdit.link_web" id="web-email" type="text"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Keterangan" label-for="keterangan">
                        <b-form-textarea v-model="formEdit.keterangan" id="keterangan"
                            placeholder="Keterangan"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="pgiBtn" @click="prepareEdit('m-confirm-edit')">
                    Simpan
                </b-button>
            </div>
        </b-modal>

        <ModalConfirm id="m-confirm-create" title="Confirm Tambah Suplier" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menambah Suplier ini ?" @submit="submitInput" />

        <ModalConfirm id="m-confirm-edit" title="Confirm Tambah Suplier" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk mengubah Suplier ini ?" @submit="submitEdit" />


        <ModalConfirm id="m-confirm-hapus" title="Confirm Hapus Suplier" :isSubmitting="isSubmitting"
            desc="Apakah anda yakin untuk menghapus Suplier ini ?" @submit="hapus" />
    </div>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    name: 'master-suplier',
    data() {
        return {
            isLoading: false,
            initStatus: 0,
            isBusy: true,
            input: {
                show: 10
            },
            bankOptions: [],
            bank: {},
            form: {
                nama: "",
                alamat: "",
                supplier_type_id: null,
                bank_id: null,
                no_rekening: null,
                nama_rekening: null,
                no_hp: null,
                link_web: "",
                keterangan: ""
            },

            activeId: null,

            formEdit: {
                nama: "",
                alamat: "",
                supplier_type_id: null,
                bank_id: null,
                bank: null,
                no_rekening: null,
                nama_rekening: null,
                no_hp: null,
                link_web: "",
                keterangan: ""
            },

            fields: [
                //{ key: "id", label: "No", sortable: true },
                { key: "nama", label: "Nama Supplier" },
                { key: "alamat", label: "Alamat" },
                { key: "no_hp", label: "No Hp" },
                { key: "supplier", label: "Tipe Supplier" },
                { key: "nama_bank", label: "Bank" },
                { key: "no_rekening", label: "No. Rekening" },
                { key: "action", label: "Aksi" },
            ],
            filter: {
                search: null,
                type_id: null,
                bank_id: null,
            },
            options: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 30, text: '30' },
                { value: 40, text: '40' },
                { value: 50, text: '50' },
            ],
            suplierAll: [],
            suplier: [],
            supplierTypeAll: [],
            supplierType: null,
            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",
            isSubmitting: false
        }
    },
    methods: {
        //----------------- Fungsi Inisialisasi -----------------
        init() {
            this.initStatus = 0
            let getSuplierType = this.$axios.get("/api/supplier-type/all").then(response => {
                let datas = response.data.data
                this.supplierTypeAll = datas.map(item => {
                    return {
                        ...item,
                        name: item.label
                    }
                })
            })

            let getBankOption = this.$axios.get("/api/admin/bank").then(response => {
                let datas = response.data.data
                this.bankOptions = datas.map(item => {
                    return {
                        id: item.id,
                        name: item.nama_bank
                    }
                })
            })

            Promise.all([getSuplierType, getBankOption]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        async getSuplier(query) {
            try {
                this.isLoading = true
                const response = await axiosClient({
                    url: 'supplier',
                    method: 'GET',
                    params: query
                })
                if (response.status === 200) {
                    const datas = response.data.data;
                    this.suplier = datas.data.map(item => {
                        return {
                            id: item.id,
                            nama: item.nama,
                            alamat: item.alamat,
                            supplier: item.supplier_type ? item.supplier_type.type : null,
                            bank: item.bank,
                            nama_bank: item.bank ? item.bank.nama_bank : "-",
                            no_rekening: item.no_rekening,
                            no_hp: item.no_hp,
                            link_web: item.link_web,
                            keterangan: item.keterangan
                        }
                    })
                    this.perPage = datas.per_page
                    this.size = this.perPage
                    this.from = datas.from
                    this.limit = datas.to

                    this.updateTotalItem(datas.total)
                    let msg = this.$helper.getFlash()
                    this.$helper.toastSucc(this, msg)
                }
            } catch (error) {
                console.log(error)
            } finally { this.isLoading = false }
        },

        // MANIPULASI QUERY
        filterSupplier() {
            const query = {
                bank_id: this.filter.bank_id ? this.filter.bank_id.id : null,
                type_id: this.filter.type_id ? this.filter.type_id.id : null,
                search: this.filter.search,
            }
            this.getSuplier(query)
        },
        filterReset() {
            this.query = ""
            this.filter = {
                bank_id: null,
                type_id: null,
                search: null,
            }
            this.getSuplier()
        },

        handleSelectBank(value) {
            this.bank = value
            this.form.bank_id = value.id
        },

        prepare(modalToShow) {

            if (this.form.nama == "") {
                return this.$helper.toastErr(this, "Mohon mengisi nama!")
            }
            if (this.form.alamat == "") {
                this.form.alamat = "-"
            }
            if (this.form.no_hp == null) {
                this.form.no_hp = "-"
            }
            if (this.form.link_web == "") {
                this.form.link_web = "-"
            }
            if (this.form.keterangan == "") {
                this.form.keterangan = "-"
            }


            this.$bvModal.show(modalToShow)

        },


        submitInput() {
            this.isSubmitting = true
            this.$axios.post("api/supplier", this.form).then(res => {

                this.$helper.toastSucc(this, "Suplier Berhasil Ditambahkan")
                this.$bvModal.hide("m-confirm-create")
                this.$bvModal.hide("m-create")

                this.form = {
                    nama: "",
                    alamat: "",
                    supplier_type_id: null,
                    bank_id: null,
                    no_rekening: null,
                    nama_rekening: null,
                    no_hp: null,
                    link_web: "",
                    keterangan: ""
                }
                this.isSubmitting = false
                this.getSuplier()

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },


        //-----------------  Fungsi Edit ----------------- 
        async prepareEdit(modalToShow) {

            if (this.formEdit.nama == "") {
                return this.$helper.toastErr(this, "Mohon mengisi nama!")
            }
            if (this.formEdit.alamat == "") {
                this.formEdit.alamat = "-"
            }
            if (this.formEdit.no_hp == null) {
                this.formEdit.no_hp = "-"
            }
            if (this.formEdit.link_web == "") {
                this.formEdit.link_web = "-"
            }
            if (this.formEdit.keterangan == "") {
                this.formEdit.keterangan = "-"
            }

            this.$bvModal.show(modalToShow)
        },

        async showModalEdit(value) {
            const response = await axiosClient({
                url: 'supplier/' + value
            })
            const data = response.data.data;
            this.activeId = value
            this.formEdit.nama = data.nama
            this.formEdit.alamat = data.alamat
            this.formEdit.no_hp = data.no_hp
            this.formEdit.supplier_type_id = data.supplier_type_id
            this.formEdit.bank = data.bank
            this.formEdit.bank_id = data.bank_id
            this.formEdit.no_rekening = data.no_rekening
            this.formEdit.nama_rekening = data.nama_rekening
            this.formEdit.link_web = data.link_web
            this.formEdit.keterangan = data.keterangan
            this.$bvModal.show('m-edit')
        },
        submitEdit() {

            this.isSubmitting = true
            this.$axios.post("api/supplier/" + this.activeId, this.formEdit).then(res => {

                this.$helper.toastSucc(this, "Suplier Berhasil Diedit")
                this.$bvModal.hide("m-confirm-edit")
                this.$bvModal.hide("m-edit")

                this.formEdit = {
                    nama: "",
                    alamat: "",
                    bank_id: null,
                    no_rekening: null,
                    nama_rekening: null,
                    no_hp: null,
                    link_web: "",
                    keterangan: ""
                }
                this.getSuplier()
                this.isSubmitting = false

            })
                .catch(error => this.$helper.parseError(this, error))
                .finally(() => this.isSubmitting = false)

        },



        //-----------------  Fungsi Hapus ----------------- 
        showModalHapus(id) {
            this.activeId = id
            this.$bvModal.show('m-confirm-hapus')
        },

        hapus() {
            this.$axios.delete("api/supplier/delete/" + this.activeId).then(response => {

                this.$helper.toastSucc(this, "Suplier Berhasil Dihapus")
                this.$bvModal.hide("m-confirm-hapus")

                this.formEdit = {
                    nama: "",
                    alamat: "",
                    no_hp: null,
                    link_web: "",
                    keterangan: ""
                }
                this.getSuplier()
            })
        },


        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------


        onFilter() {
            this.page = 1
            this.currentPage = 1
            this.query = "?page=" + this.page
            this.loading = true
            this.getSuplier()
        },

        async onPageChange() {
            if (this.loading == false) {
                this.page = this.currentPage
                await this.getSuplier({ ...this.query, page: this.currentPage })
            }
        },

        onLimitChange() {
            this.perPage = this.input.show
            this.onPageChange()
        },

        onReset() {
            this.filter = null
            this.page = 1
            this.query = "?page=" + this.page
            this.currentPage = 1
            this.loading = true
            this.getSuplier()
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

        inputJenisBarang() {
            this.$router.push({ path: `${this.$route.path}/input` });
        },
    },

    created() {
        this.init()
        this.getSuplier()
    }
}
</script>
